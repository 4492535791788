import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeadData from '../components/HeadData';
import StoreAppointments from "../components/StoreAppointments";

const StoreAppointmentsPage = () => {
    const { t, i18n } = useTranslation();
    const title = t('store-appointments.title');
    const description = t('store-appointments.description');
    const keywords = t('store-appointments.keywords');
    const lang = i18n.language;
    const { storeID } = useParams();
    return (
        <>
            <HeadData title={title} description={description}/>
            <StoreAppointments storeID={storeID} />
        </>
    );
};

export default StoreAppointmentsPage;