import React from 'react';

const LoginRequiredMessage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Please login to access this page.</h2>
      <a href="/login">Go To Login Page</a>
    </div>
  );
};

export default LoginRequiredMessage;