import WorkingHoursModel from '../models/WorkingHoursModel';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

const fetchWorkingHoursByStoreID = async (storeID) => {
    try{
        const workingHoursBody = {
            storeID: storeID // pass storeID as a parameter
        };
        const workingHoursResponse = await fetch(`${API_ENDPOINT_URL}/working-hours/get-working-hours-by-store-id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': process.env.REACT_APP_API_TOKEN
            },
            body: JSON.stringify(workingHoursBody)
        });
        if (workingHoursResponse.ok) {
            const workingHoursData = await workingHoursResponse.json();
            return new WorkingHoursModel(workingHoursData);
        } else {
            console.log("Getting working hours Error: " + workingHoursResponse.status + " " + workingHoursResponse.statusText);
        }
    }catch(e){
        console.error('Error fetching working hours data:', e);
    }
    
    return null;
};

export { fetchWorkingHoursByStoreID };