import React  from 'react';
import LoginRequiredMessage from "../components/LoginRequiredMessage";
import HeadData from '../components/HeadData';
const LoginRequiredMessagePage = () => {
    return (
    <>
      <LoginRequiredMessage/>
    </>
        
    );
};
  
export default LoginRequiredMessagePage;