import React, { useState, useLayoutEffect, useEffect } from "react";
import SidebarMenu from "../components/dashboard/SidebarMenu";
import DashboardContentWrapper from "../components/dashboard/DashboardContentWrapper";

function StoreDashboard() {
    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <SidebarMenu/>
                <DashboardContentWrapper/>
            </div>
        </div>
    );
  }
  
  export default StoreDashboard;