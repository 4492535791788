import React  from 'react';
import { Outlet } from 'react-router-dom'
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = () => {
  return (
    <div className="container-fluid">
      <Header />
      <main className="py-5">
        <div className="container">
            <Outlet />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;