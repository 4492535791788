import StoreModel from '../models/StoreModel';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

const fetchStoreById = async (storeID) => {
    try{
        const storeBody = {
            storeID: storeID
        };
        const response = await fetch(`${API_ENDPOINT_URL}/stores/get-store-by-store-id`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'API-TOKEN': process.env.REACT_APP_API_TOKEN
            },
            body: JSON.stringify(storeBody)
        });
    
        if (response.ok) {
            const data = await response.json();
            return new StoreModel(data);
        }  else {
            console.log("Getting store data Error: " + response.status + " " + response.statusText);
        }
    }catch(e){
        console.error('Error fetching store data:', e);
    }
    
    return null;
};

export { fetchStoreById };