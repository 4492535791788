import ServicesModel from '../models/ServicesModel';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

const fetchServicesByStoreID = async (storeID) => {
    try{
        const servicesBody = {
            storeID: storeID // pass storeID as a parameter
          };
          const servicesResponse = await fetch(`${API_ENDPOINT_URL}/services/get-services-by-store-id`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'API-TOKEN': process.env.REACT_APP_API_TOKEN
              },
              body: JSON.stringify(servicesBody)
          });
          if (servicesResponse.ok) {
              const servicesData = await servicesResponse.json();
              return new ServicesModel(servicesData);
          } else {
              console.log("Getting services Error: " + servicesResponse.status + " " + servicesResponse.statusText);
          }
    }catch(e){
        console.error('Error fetching services data:', e);
    }
    
    return null;
};

export { fetchServicesByStoreID };