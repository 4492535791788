import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function Login(){
    const { t } = useTranslation();
    const [formValid, setFormValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [inputFields, setInputFields] = useState({
        login: '',
        password: ''
    });

    function handleInputChange(e){
        const field = e.target.name;
        const value = e.target.value;
        switch (field) {
            case 'login':
            inputFields.username = value;
            break;
            case 'password':
            inputFields.password = value;
            break;
            default:
            break;
        }
        setInputFields(inputFields);
    }
    
    function validateForm(inputFields) {
        let errors = {};
        let formIsValid = true;
        for (let field in inputFields) {
        switch (field) {
            case 'login':
            if (inputFields[field].length < 3) {
                formIsValid = false;
                errors[field] = t('Username/Email must be at least 3 characters');
            }
            break;
            case 'password':
            if (inputFields[field].length < 6) {
                formIsValid = false;
                errors[field] = t('Password must be at least 6 characters');
            }
            break;
            default:
            break;
        }
        }
        setErrors(errors);
        setFormValid(formIsValid);
    }
    
    function onFormChange(e) {
        e.preventDefault();
        const { name, value } = e.target;
        const newInputFields = { ...inputFields, [name]: value };
        setInputFields(newInputFields);
        validateForm(newInputFields);
    }
    
    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (formValid) {
            const body = {};
            body.login = inputFields.login;
            body.password = inputFields.password;

            const response = await fetch(`${API_ENDPOINT_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'API-TOKEN': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(body)
            });
            if (response.ok) {
                const data = await response.json();
                let errors = {};
                if(data.errors){
                    console.log(data);
                    for (let field in data.errors) {
                        errors[field] = data.errors[field];
                    }
                    setErrors(errors);
                } else {
                    // Store the user information in the local storage
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('token', JSON.stringify(data.token));
                    // Redirect to the home page
                    window.location.href = '/';
                }
            }else {
                throw new Error(response.statusText);
            }
        } else {
            validateForm(inputFields);
        }
    }

    return (
    <form onChange={onFormChange} onSubmit={onFormSubmit} className="needs-validation">

      <div className="mb-3">
        <label className="form-label">{t('Username')}/Email</label>
        <input className="form-control" type="text" name="login" onChange={handleInputChange}/>
      </div>

      <div className="mb-3">
        <label className="form-label">{t('Password')}</label>
        <input className="form-control" type="password" name="password" onChange={handleInputChange}/>
      </div>

      <div className="mb-3">
        <button type="submit" className="btn btn-primary">{t('Login')}</button>
      </div>
      
      <div className="mb-3">
        {errors.login && <div className="alert alert-danger" role="alert">{errors.login}</div>}
        {errors.password && <div className="alert alert-danger" role="alert">{errors.password}</div>}
      </div>
      
    </form>
    );
                
}

export default Login;