import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/common.css";
import "font-awesome/css/font-awesome.min.css";
import "./i18n";
import jwt_decode from "jwt-decode";
import MaintainancePage from "./pages/MaintainancePage";
import Layout from "./pages/Layout.js";
import LoginRequiredMessagePage from "./pages/LoginRequiredMessagePage";
import HomePage from "./pages/HomePage";
import NoPage from "./pages/NoPage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import StoreDashboardPage from "./pages/StoreDashboardPage";
import StoreAppointmentsPage from "./pages/StoreAppointmentsPage";

export default function App() {
  const [isTokenValid, setIsTokenValid] = useState(null);
  const isMaintenanceMode = process.env.REACT_APP_MAINTAINANCE_MODE;

  useEffect(() => {
    checkToken();
  }, []);

  setInterval(checkToken, 60000);

  if (isMaintenanceMode === "true") {
    return <MaintainancePage />;
  } else {
    if (isTokenValid === null) {
      return <div>Loading...</div>;
    }

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<NoPage />} />
            <Route path="/store/:storeID" element={<StoreAppointmentsPage />} />
          </Route>
          {isTokenValid ? (
            <Route path="dashboard" element={<StoreDashboardPage />} />
          ) : (
            <Route
              path="dashboard"
              element={<LoginRequiredMessagePage />}
            />
          )}
        </Routes>
      </BrowserRouter>
    );
  }
  function checkToken(){
    const token = localStorage.getItem("token");
    if (!token) {
      setIsTokenValid(false);
      return;
    }
    try {
      const decodedToken = jwt_decode(token);
      const currentTime = new Date().getTime() / 1000;
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        setIsTokenValid(false);
      } else {
        setIsTokenValid(true);
      }
    } catch (err) {
      setIsTokenValid(false);
    }
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
