import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'flag-icon-css/css/flag-icons.min.css';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    toggleDropdown();
  };

  let flagClass;
  if (i18n.language === 'ba') {
    flagClass = 'flag-icon flag-icon-ba';
  } else if (i18n.language === 'de') {
    flagClass = 'flag-icon flag-icon-de';
  } else {
    flagClass = 'flag-icon flag-icon-us';
  }

  return (
    <div className="dropdown">
      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      <span className={flagClass} /> {t('Language')}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li role="button"><a className="dropdown-item" onClick={() => changeLanguage('en')}><span className="flag-icon flag-icon-us" /> English</a></li>
        <li role="button"><a className="dropdown-item" onClick={() => changeLanguage('de')}><span className="flag-icon flag-icon-de" /> Deutsch</a></li>
        <li role="button"><a className="dropdown-item" onClick={() => changeLanguage('ba')}><span className="flag-icon flag-icon-ba" /> Bosanski</a></li>
      </ul>
    </div>
  );
};

export default LanguageSelector;