import React from 'react';
import { useTranslation } from 'react-i18next';
import HeadData from '../components/HeadData';

const HomePage = () => {
  const { t, i18n } = useTranslation();

  const title = t('homepage.title');
  const description = t('homepage.description');
  const keywords = t('homepage.keywords');
  const lang = i18n.language;

  return (
    <>
      <HeadData title={title} description={description} keywords={keywords} lang={lang} />
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2 text-center">
            <h2 className="mt-5 mb-4 heading">{t('homepage.heading')}</h2>
            <p className="lead intro">{t('homepage.intro')}</p>
          </div>
        </div>

        {/* Additional Sections */}
        <div className="row mt-5 features-section">
          <div className="col-md-6">
            <h3>{t('homepage.keyFeatures')}</h3>
            <ul className="feature-list">
              <li>{t('homepage.feature1')}</li>
              <li>{t('homepage.feature2')}</li>
              <li>{t('homepage.feature3')}</li>
              <li>{t('homepage.feature4')}</li>
              {/* Add more features as needed */}
            </ul>
          </div>

          <div className="col-md-6">
            <h3>{t('homepage.howItWorks')}</h3>
            <ol className="step-list">
              <li>{t('homepage.step1')}</li>
              <li>{t('homepage.step2')}</li>
              <li>{t('homepage.step3')}</li>
              {/* Add more steps as needed */}
            </ol>
          </div>
        </div>

        <div className="row mt-5 cta-section">
          <div className="col-md-8 offset-md-2 text-center">
            <h3>{t('homepage.ctaHeading')}</h3>
            <p className="cta-description">{t('homepage.ctaDescription')}</p>
            <a href="/register">
              <button className="btn btn-primary cta-button">{t('Sign Up')}</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
