import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input'
import { fetchStoreById } from '../services/StoreService';
import { fetchEmployeesByStoreID } from '../services/EmployeesService';
import { fetchServicesByStoreID } from '../services/ServicesService';
import { fetchWorkingHoursByStoreID } from '../services/WorkingHoursService';
import { fetchAppointmentsByStoreID, addAppointment } from '../services/AppointmentsService';

const StoreAppointments = ({ storeID }) => {

    const { t } = useTranslation();
    const [storeData, setStoreData] = useState(null);
    const [employeesData, setEmployeeData] = useState(null);
    const [servicesData, setServicesData] = useState(null);
    const [workingHoursData, setWorkingHoursData] = useState(null);
    const [appointmentsData, setAppointmentsData] = useState(null);
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    // form data
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedAppointmentStartTime, setSelectedAppointmentStartTime] = useState('');
    const [selectedAppointmentEndTime, setSelectedAppointmentEndTime] = useState('');
    const [errors, setErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        const fetchStoreData = async () => {
          try {

            const storeData = await fetchStoreById(storeID);
            if(storeData){
              setStoreData(storeData);
            }

            const employeesData = await fetchEmployeesByStoreID(storeID);
            if(employeesData.employees){
              setEmployeeData(employeesData.employees);
            }

            const servicesData = await fetchServicesByStoreID(storeID);
            if(servicesData.services){
              setServicesData(servicesData.services);
            }

            const workingHoursData = await fetchWorkingHoursByStoreID(storeID);
            if(workingHoursData.workingHours){
              setWorkingHoursData(workingHoursData.workingHours);
            }

            const appointmentsData = await fetchAppointmentsByStoreID(storeID);
            if(appointmentsData.appointments){
              setAppointmentsData(appointmentsData.appointments);
            }

          } catch (error) {
            // TODO: Add here handling to show some message to user
            console.error('Error fetching data:', error);
          }
        };
    
        // Call the function to fetch data when the component mounts
        fetchStoreData();
      }, [storeID]); // Add storeID to the dependency array to re-fetch data when it changes

    useEffect(() => {
      calculateAvailableTimeSlots();
    }, [selectedDate]);

    useEffect(() => {
      validateForm();
    }, [firstName, lastName, email, phoneNumber, selectedEmployee, selectedService, selectedDate, selectedTime]);

    useEffect(() => {
      if (selectedDate && selectedTime && availableTimeSlots.length > 0) {
        setAppointmentStartAndEndTime();
      }
    }, [selectedTime]);

    const handlePhoneChange = (e) => {
      if (typeof e === 'string' || e === null) {
        setPhoneNumber(e);
      }
    };

    const handleFormSubmit = (e) => {
      e.preventDefault();
    
      if(validateForm()){
        setShowErrors(false);
        
        // save data
        const newAppointment = {
            serviceID: parseInt(selectedService),
            employeeID: parseInt(selectedEmployee),
            storeID: storeID,
            appointmentStartTime: selectedAppointmentStartTime,
            appointmentEndTime: selectedAppointmentEndTime,
            customerFirstName: firstName,
            customerLastName: lastName,
            customerEmail: email,
            customerPhoneNumber: phoneNumber,
            appointmentStatus: "pending" // set appointment status pending, need to be confirmed by store
        }
        addAppointment(newAppointment);

        // TODO: Redirect user and display thank you message

      } else {
        setShowErrors(true);
      }
    };

    const validateForm = () => {
      const errors = [];
      let hasErrors = false;

      if(firstName.trim().length < 2){
        errors.push(t('First Name must be at least 3 characters'));
        hasErrors = true;
      }
      if(lastName.trim().length < 2){
        errors.push(t('Last Name must be at least 3 characters'));
        hasErrors = true;
      }
      if(!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
        errors.push(t('Email is invalid'));
        hasErrors = true;
      }
      if (isEmpty(phoneNumber) || !isValidPhoneNumber(phoneNumber)) {
        errors.push(t('Phone is invalid'));
        hasErrors = true;
      }
      if(isEmpty(selectedEmployee)){
        errors.push("Please select employee");
        hasErrors = true;
      }
      if(isEmpty(selectedService)){
        errors.push("Please select service");
        hasErrors = true;
      }
      if(isEmpty(selectedDate)){
        errors.push("Please choose date for appointment");
        hasErrors = true;
      }
      if(isEmpty(selectedTime)){
        errors.push("Please choose time for appointment");
        hasErrors = true;
      }

      setErrors(errors);
      return !hasErrors;

    };

    const isEmpty = (value) => {
      if(value === '' || value === 0 || value === null || value === undefined){
        return true;
      }
      return false;
    };

    const calculateAvailableTimeSlots = () => {
      if (selectedEmployee && selectedService) {
        const service = servicesData.find(service => service.serviceID === parseInt(selectedService, 10));

        const selectedDateObject = new Date(selectedDate);
        const selectedDay = selectedDateObject.getDay();
        const employeeWorkingHours = workingHoursData.find(wh => 
          wh.employeeID === parseInt(selectedEmployee, 10) && 
          wh.workingHoursDayOfWeek === selectedDay
        );

        if (employeeWorkingHours) {
          const employeeAppointments = appointmentsData.filter(appointment => appointment.employeeID === parseInt(selectedEmployee, 10));
    
          const calculatedSlots = calculateAvailableSlots(employeeWorkingHours, service.serviceDuration, employeeAppointments);
    
          // Extract available time slots from calculatedSlots
          const availableTimeSlots = calculatedSlots.map(slot => slot.time);
          setAvailableTimeSlots(availableTimeSlots);
        }
      }
    };

    const calculateAvailableSlots = (employeeWorkingHours, serviceDuration, appointments) => {
      const availableSlots = [];
    
      if (employeeWorkingHours && selectedDate) {
        // Create a Date object for the selected day
        const currentDate = new Date(selectedDate);

        // Create a Date object for the working day and working hours
        const workingDayStart = new Date(currentDate);
        workingDayStart.setUTCHours(...employeeWorkingHours.workingHoursStartTime.split(':'));
        const workingDayEnd = new Date(currentDate);
        workingDayEnd.setUTCHours(...employeeWorkingHours.workingHoursEndTime.split(':'));
        
        // Iterate through time slots within working hours
        let currentSlot = new Date(workingDayStart);
        while (currentSlot < workingDayEnd) {
          const slotEndTime = new Date(currentSlot.getTime() + serviceDuration * 60000); // Convert minutes to milliseconds
          // Check if the slot is within working hours and not already booked
          if (
            currentSlot.getUTCDay() === employeeWorkingHours.workingHoursDayOfWeek &&
            slotEndTime <= workingDayEnd &&
            !appointmentsOverlap(currentSlot, slotEndTime, appointments)
          ) {
            availableSlots.push({
              time: currentSlot.toISOString().split('T')[1].substring(0, 5), // Extract time in HH:mm format
              formattedTime: formatTime(currentSlot),
            });
          }
    
          // Move to the next time slot
          currentSlot = new Date(currentSlot.getTime() + serviceDuration * 60000); // Move to the next serviceDuration-minute slot
        }
      }
    
      return availableSlots;
    };
    
    
    const appointmentsOverlap = (start1, end1, appointments) => {
      // Check if the new appointment overlaps with existing appointments
      return appointments.some(appointment => {
        const start2 = new Date(appointment.appointmentStartTime);
        const end2 = new Date(appointment.appointmentEndTime);
    
        return (start1 < end2 && end1 > start2);
      });
    };
    
    const formatTime = (date) => {
      // Format date to HH:mm
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const setAppointmentStartAndEndTime = () => {
      if (selectedDate && selectedTime && availableTimeSlots.length > 0) {
        const appointmentStartDateTimeString = `${selectedDate}T${selectedTime}`;
        const appointmentStartDateTime = new Date(appointmentStartDateTimeString + 'Z').toISOString().slice(0, 19).replace('T', ' '); // 'Z' indicates UTC

        // Find the index of the endTime in availableTimeSlots - basicaly this is next option from selected one
        const selectedTimeIndex = availableTimeSlots.findIndex(slot => slot === selectedTime);

        // Calculate end time based on the next available time slot
        const nextAvailableTime = availableTimeSlots[selectedTimeIndex + 1];
        const appointmentEndDateTimeString = `${selectedDate}T${nextAvailableTime}`;
        const appointmentEndDateTime = new Date(appointmentEndDateTimeString + 'Z').toISOString().slice(0, 19).replace('T', ' ');

        setSelectedAppointmentStartTime(appointmentStartDateTime);
        setSelectedAppointmentEndTime(appointmentEndDateTime);
      }
    };

    return (
    <div className="container mt-5 store-appointments-container">
        {storeData ? (
        <>
        <h2 className="mb-4">
          Book An Appointent at {storeData.storeName}
        </h2>

        <div className="card">
            <div className="card-body">
            <form className="appointment-form">
                {showErrors && (
                <div className="form-group errors-container">
                    {Object.keys(errors).length > 0 &&
                        Object.entries(errors).map(([fieldName, errorMessage]) => (
                        <div key={fieldName} className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    ))}
                </div>
                )}
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                      First Name
                  </label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="firstName" 
                    required 
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                <label htmlFor="lastName" className="form-label">
                    Last Name
                </label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="lastName" 
                    required 
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                <label htmlFor="email" className="form-label">
                    Email Address
                </label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="email" 
                    required 
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                <label htmlFor="phone" className="form-label">
                    Phone Number
                </label>
                  <PhoneInput
                      placeholder={t("Enter phone number")}
                      value={phoneNumber}
                      onChange={(e) => {
                        handlePhoneChange(e);
                      }}
                      className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="employee" className="form-label">
                  {t('Select an employee:')}
                  </label>
                  <select
                    className="form-control"
                    id="employee"
                    value={selectedEmployee}
                    onChange={(e) => {
                      setSelectedEmployee(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select an employee</option>
                    {employeesData &&
                      employeesData.map((employee) => (
                        <option key={employee.employeeID} value={employee.employeeID}>
                          {employee.employeeFirstName} {employee.employeeLastName}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="service" className="form-label">
                  {t('Select a service:')}
                  </label>
                  <select
                    className="form-control"
                    id="service"
                    value={selectedService}
                    onChange={(e) => {
                      setSelectedService(e.target.value);
                    }}
                    disabled={!selectedEmployee} // Disable if employee is not selected
                    required
                  >
                    <option value="">Select a service</option>
                    {servicesData &&
                      servicesData.map((service) => (
                        <option key={service.serviceID} value={service.serviceID}>
                          {service.serviceName}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="appointmentDate" className="form-label">
                    Preferred Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="appointmentDate"
                    min={new Date().toISOString().split('T')[0]}
                    required
                    disabled={!selectedService} // Disable if service is not selected
                    onChange={(e) => {
                      setSelectedDate(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="appointmentTime" className="form-label">
                    Preferred Time
                  </label>
                  <select
                    className="form-control"
                    id="appointmentTime"
                    value={selectedTime}
                    onChange={(e) => {
                      setSelectedTime(e.target.value);
                    }}
                    disabled={!selectedDate} // Disable if date is not selected
                    required
                  >
                    <option value="">Select a time</option>
                    {availableTimeSlots.map((slot, index) => (
                      <option key={index} value={slot}>
                        {slot}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  type="button"
                  className="btn btn-schedule"
                  onClick={handleFormSubmit}
                >
                  <i className="fa fa-calendar"></i> Schedule Now
                </button>
            </form>
            </div>
        </div>
        </>
        ) : (
        <p>{t('Loading store data...')}</p>
        )}
    </div>
    );
};

export default StoreAppointments;
