import React from "react";
import { useTranslation } from 'react-i18next';

function SidebarMenu() {

    const { t } = useTranslation();

    const userLocalStorage = localStorage.getItem('user');
    const user = JSON.parse(userLocalStorage);

    const handleLogout = () => {
        // Clear user local storage
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        // Redirect to login page or another page
        window.location.href = '/login';
    };

    return (
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
            <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                <a href="#" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-5 d-none d-sm-inline">{t('Menu')}</span>
                </a>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a href="#" className="nav-link px-0 align-middle active" data-bs-toggle="pill" data-bs-target="#pills-dashboard" role="tab" aria-controls="pills-dashboard" aria-selected="true">
                        <i className="nav-icon fs-4 bi-speedometer2"></i> <span className="ms-1 d-none d-sm-inline">{t('Dashboard')}</span> 
                        </a>
                    </li>
                    
                    <li className="nav-item" role="presentation">
                        <a href="#" className="nav-link px-0 align-middle" data-bs-toggle="pill" data-bs-target="#pills-mystore" role="tab" aria-controls="pills-mystore" aria-selected="false">
                        <i className="nav-icon fs-4 bi-shop"></i> <span className="ms-1 d-none d-sm-inline">{t('My Store')}</span>
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="#" className="nav-link px-0 align-middle" data-bs-toggle="pill" data-bs-target="#pills-employees" role="tab" aria-controls="pills-employees" aria-selected="false">
                        <i className="nav-icon fs-4 bi bi-people"></i> <span className="ms-1 d-none d-sm-inline">{t('Employees')}</span>
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="#" className="nav-link px-0 align-middle" data-bs-toggle="pill" data-bs-target="#pills-working-hours" role="tab" aria-controls="pills-working-hours" aria-selected="false">
                        <i className="nav-icon fs-4 bi bi-clock"></i> <span className="ms-1 d-none d-sm-inline">{t('Working Hours')}</span>
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="#" className="nav-link px-0 align-middle" data-bs-toggle="pill" data-bs-target="#pills-services" role="tab" aria-controls="pills-services" aria-selected="false">
                        <i className="nav-icon fs-4 bi bi-briefcase"></i> <span className="ms-1 d-none d-sm-inline">{t('Services')}</span>
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a href="#" className="nav-link px-0 align-middle" data-bs-toggle="pill" data-bs-target="#pills-appointments" role="tab" aria-controls="pills-appointments" aria-selected="false">
                        <i className="nav-icon fs-4 bi-table"></i> <span className="ms-1 d-none d-sm-inline">{t('Appointments')}</span>
                        </a>
                    </li>
                </ul>
                <div className="dropdown pb-4">
                    <a href="#" className="d-flex align-items-center text-white text-decoration-none" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="/images/dashboard/no-avatar.jpg" alt="hugenerd" width="30" height="30" className="rounded-circle" />
                        <span className="d-none d-sm-inline mx-1">{user.username}</span>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                    <li><a className="dropdown-item" href="#">Settings</a></li>
                    <li><a className="dropdown-item" href="#">Profile</a></li>
                    <li>
                        <hr className="dropdown-divider" />
                    </li>
                    <li><a onClick={handleLogout} className="dropdown-item" href="#">Sign out</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
  }
  
  export default SidebarMenu;