class StoreModel {
    constructor(data) {
        if(data && data.store){
            this.storeID = data.store.storeID;
            this.storeDescription = data.store.storeDescription;
            this.storeEmail = data.store.storeEmail;
            this.storeIsDeleted = data.store.storeIsDeleted;
            this.storeName = data.store.storeName;
            this.storePhone = data.store.storePhone;
            this.userID = data.store.userID;
        }
    }
}

export default StoreModel;