import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;
const defaultInputFields = {
  username: '',
  email: '',
  password: '',
  confirmPassword: ''
};

function Register() {
  const { t } = useTranslation();
  const [formValid, setFormValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputFields, setInputFields] = useState(defaultInputFields);

function validateForm(inputFields) {
  let errors = {};
  let formIsValid = true;
  for (let field in inputFields) {
    switch (field) {
      case 'email':
        if (!inputFields[field].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          formIsValid = false;
          errors[field] = t('Email is invalid');
        }
        break;
      case 'username':
        if (inputFields[field].length < 3) {
          formIsValid = false;
          errors[field] = t('Username must be at least 3 characters');
        }
        break;
      case 'password':
        if (inputFields[field].length < 6) {
          formIsValid = false;
          errors[field] = t('Password must be at least 6 characters');
        }
        break;
      case 'confirmPassword':
        if (inputFields[field] !== inputFields.password) {
          formIsValid = false;
          errors[field] = t('Passwords do not match');
        }
        break;
      default:
        break;
    }
  }
  setErrors(errors);
  setFormValid(formIsValid);
}

function onFormChange(e) {
  e.preventDefault();
  const { name, value } = e.target;
  const newInputFields = { ...inputFields, [name]: value };
  setInputFields(newInputFields);
  validateForm(newInputFields);
}

function handleInputChange(e) {
  const field = e.target.name;
  const value = e.target.value;
  switch (field) {
    case 'email':
      inputFields.email = value;
      break;
    case 'username':
      inputFields.username = value;
      break;
    case 'password':
      inputFields.password = value;
      break;
    case 'confirmPassword':
      inputFields.confirmPassword = value;
      break;
    default:
      break;
  }
  setInputFields(inputFields);
};

const onFormSubmit = async (event) => {
  event.preventDefault();
  try {
    if (formValid) {
      const body = {};
      body.username = inputFields.username;
      body.email = inputFields.email;
      body.password = inputFields.password;

      const response = await fetch(`${API_ENDPOINT_URL}/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'API-TOKEN': process.env.REACT_APP_API_TOKEN
        },
        body: JSON.stringify(body)
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const data = await response.json();
      let errors = {};
      if(data.errors){
        for (let field in data.errors) {
          errors[field] = data.errors[field];
        }
        setErrors(errors);
      } else {
        // redirect to login page
        window.location.href = '/login';
      }
    } else {
      validateForm(inputFields);
    }
  } catch (error) {
    errors['form'] = 'Something went wrong. Please contact administrators.';
  }
};

  return (
    
    <form onChange={onFormChange} onSubmit={onFormSubmit} className="needs-validation">
      <div className="mb-3">
        <label className="form-label">{t('Email')}</label>
        <input className="form-control" type="email" name="email" onChange={handleInputChange}/>
      </div>

      <div className="mb-3">
        <label className="form-label">{t('Username')}</label>
        <input className="form-control" type="text" name="username" onChange={handleInputChange}/>
      </div>

      <div className="mb-3">
        <label className="form-label">{t('Password')}</label>
        <input className="form-control" type="password" name="password" onChange={handleInputChange}/>
      </div>

      <div className="mb-3">
        <label className="form-label">{t('Confirm Password')}</label>
        <input className="form-control" type="password" name="confirmPassword" onChange={handleInputChange}/>
      </div>

      <div className="mb-3">
        <button type="submit" className="btn btn-primary">{t('Register')}</button>
      </div>
      
      <div className="mb-3">
        {errors.email && <div className="alert alert-danger" role="alert">{errors.email}</div>}
        {errors.username && <div className="alert alert-danger" role="alert">{errors.username}</div>}
        {errors.password && <div className="alert alert-danger" role="alert">{errors.password}</div>}
        {errors.confirmPassword && <div className="alert alert-danger" role="alert">{errors.confirmPassword}</div>}
        {errors.form && <div className="alert alert-danger" role="alert">{errors.form}</div>}
      </div>
      
    </form>
  );
}

export default Register;