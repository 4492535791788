class EmployeesModel {
    constructor(data) {
        this.employees = [];
        if (data && data.employees) {
            this.employees = data.employees.map((employeeData) => {
                return {
                    employeeID: employeeData.employeeID,
                    employeeFirstName: employeeData.employeeFirstName,
                    employeeLastName: employeeData.employeeLastName
                };
            });
        }
    }
}

export default EmployeesModel;