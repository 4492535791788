import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import AddNewAppointmentModal from "./AddNewAppointmentModal";
import EditAppointmentModal from "./EditAppointmentModal";

function Appointments(props) {
    const { t } = useTranslation();
    // params
    const { dashboardData, getEmployeeHoursForAllDays, addNewAppointmentToDashboardData, updateAppointmentData } = props;
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    // set state for selected employee
    const [selectedEmployeeID, setSelectedEmployeeID] = useState(0);
    // set state for selected service
    const [selectedServiceID, setSelectedServiceID] = useState(0);
    // set state for selected date
    const [selectedDate, setSelectedDate] = useState(new Date());
    // set state for working hours set for selected employee
    const [employeeWorkingHours, setEmployeeWorkingHours] = useState({});
    // set state for reservation times
    const [timeSlots, setTimeSlots] = useState([]);
    // set state for clicked appointment start time
    const [appointmentStartTime, setAppointmentTime] = useState([]);
    // set state for success message
    const [success, setSuccessMessage] = useState('');

    // Add New Appointment Modal
    const [showAddNewAppointmentModal, setShowAddNewAppointmentModal] = useState(false);
    // Edit Appointment Modal
    const [showEditAppointmentModal, setShowEditAppointmentModal] = useState(false);
    // set appointmentID for appointment to be edited
    const [editAppointmentData, setEditAppointmentData] = useState({});

    // update table whenever selected date changes
    useEffect(() => {
        updateTable();
    }, [selectedDate]);
    // update table whenever selected employee changes
    useEffect(() => {
        updateTable();
    }, [selectedEmployeeID]);
    // update table whenever selected service changes
    useEffect(() => {
        updateTable();
    }, [selectedServiceID]);

    useEffect(() => {
        if(success){
            // update table
            updateTable();
            // go to top of the page
            window.scrollTo(0, 0);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    }, [success]);

    // methods

    const handleEmployeeSelect = (event) => {
        event.preventDefault();
        const selectedEmployeeID = parseInt(event.target.value);
        // check if working hours are set for selected employee
        const selectedEmployeeWorkingHours = getEmployeeHoursForAllDays(selectedEmployeeID);
        // set employee selected
        if(selectedEmployeeID > 0){
            // set working hours set for selected employee
            if(selectedEmployeeWorkingHours.length > 0){
                setEmployeeWorkingHours(selectedEmployeeWorkingHours);
            }else{
                setEmployeeWorkingHours({});
            }
        } 
        setSelectedEmployeeID(selectedEmployeeID);
        
    }

    const handleServiceSelect = (event) => {
        event.preventDefault();
        const selectedServiceID = parseInt(event.target.value);
        setSelectedServiceID(selectedServiceID);
    }

    const handlePreviousDay = () => {
        const previousDay = new Date(selectedDate);
        previousDay.setDate(selectedDate.getDate() - 1);
        setSelectedDate(previousDay);
    };

    const handleNextDay = () => {
        const nextDay = new Date(selectedDate);
        nextDay.setDate(selectedDate.getDate() + 1);
        setSelectedDate(nextDay);
    };
    const updateTable = () => {
        const dayOfWeek = daysOfWeek[selectedDate.getDay()];
        // get key from daysOfWeek array e.g. Sunday = 0, Monday = 1, etc.
        const dayOfWeekKey = daysOfWeek.indexOf(dayOfWeek);
        // get service duration for selected service
        const service = dashboardData.services.find(
          (service) => service.serviceID === selectedServiceID
        );
        if (!service) {
          // TODO: Add handling to show message that no service is selected
          return;
        }
        const serviceDuration = service.serviceDuration;
        // check if employees hours are set for selected day
        if (employeeWorkingHours.length > 0) {
          // loop through employee hours and find the one for selected day
          employeeWorkingHours.forEach((employeeWorkingHour) => {
            if (employeeWorkingHour.workingHoursDayOfWeek === dayOfWeekKey) {
                const newTimeSlots = [];
                const startTime = new Date(selectedDate);
                startTime.setHours(
                    employeeWorkingHour.workingHoursStartTime.split(":")[0],
                    employeeWorkingHour.workingHoursStartTime.split(":")[1]
                );
                startTime.setSeconds(0);
                startTime.setMilliseconds(0);
                const endTime = new Date(selectedDate);
                endTime.setHours(employeeWorkingHour.workingHoursEndTime.split(':')[0], employeeWorkingHour.workingHoursEndTime.split(':')[1]);
                endTime.setSeconds(0);
                endTime.setMilliseconds(0);
              while (startTime < endTime) {
                // create new object and name it newObject
                const timeSlot = {
                  appointmentStartTime: new Date(startTime),
                  appointmentEndTime: new Date(startTime.getTime() + serviceDuration * 60000),
                  availability: "available",
                  status: ""
                };
                newTimeSlots.push(timeSlot);
                startTime.setMinutes(startTime.getMinutes() + serviceDuration);
              }
              // loop through appointments and set status for each time slot (available, reserved, unavailable)
              dashboardData.appointments.forEach((appointment) => {
                // only check appointments for selected employee
                if(appointment.employeeID === selectedEmployeeID){
                    const appointmentStartTime = new Date(appointment.appointmentStartTime);
                    const appointmentEndTime = new Date(appointment.appointmentEndTime);
                    newTimeSlots.forEach((timeSlot) => {
                    // check if time slot is available
                    if (timeSlot.appointmentStartTime.toString() === appointmentStartTime.toString()) {
                        timeSlot.availability = "reserved";
                        timeSlot.status = appointment.appointmentStatus;
                        timeSlot.appointmentID = appointment.appointmentID;
                    // check if there is overlap with an existing appointment
                    } else if (isPeriodOverlap(appointmentStartTime, appointmentEndTime, timeSlot.appointmentStartTime, timeSlot.appointmentEndTime)) {
                        timeSlot.availability = "unavailable";
                        timeSlot.overlapAppointment = appointment;
                        // get service for appointment
                        const appointmentService = dashboardData.services.find(
                            (service) => service.serviceID === appointment.serviceID
                        );
                        timeSlot.overlapAppountmentService = appointmentService;
                    }
                    });
                }
              });
              // set time slots
              setTimeSlots(newTimeSlots);
            }
          });
        } else {
          // TODO: Add handling to show message that no working hours are set for selected employee
          console.log("No working hours set for selected employee");
        }
      };
      
    const isPeriodOverlap = (date1Start, date1End, date2Start, date2End) => {
        // TODO: Need to check if this is correct
        return (date1Start >= date2Start && date1Start < date2End) ||
                (date1End > date2Start && date1End <= date2End) ||
                (date1Start <= date2Start && date1End >= date2End);
    };
    const openAddNewAppointmentModal = (appointmentStartTime) => {
        setShowAddNewAppointmentModal(true);
        setAppointmentTime(appointmentStartTime);
        
    }

    const openEditAppointmentModal = (appointmentID) => {
        const appointment = dashboardData.appointments.find((appointment) => appointment.appointmentID === appointmentID);
        setEditAppointmentData(appointment);
        setShowEditAppointmentModal(true);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="success-message">
                    {success && <div className="alert alert-success" role="alert">{t(success)}</div>}
                </div>
                <div className="col-6">
                    <div className="mb-3">
                    <label htmlFor="employeeSelect" className="form-label">{t('Select an employee:')}</label>
                    <select className="form-select" id="employeeSelect" onChange={handleEmployeeSelect}>
                        <option value="0">-</option>
                        {dashboardData.employees.map((employee) => (
                        <option key={employee.employeeID} value={employee.employeeID}>{`${employee.employeeFirstName} ${employee.employeeLastName}`}</option>
                        ))}
                    </select>
                </div>
                </div>
                <div className="col-6">
                    <div className="mb-3">
                    <label htmlFor="employeeSelect" className="form-label">{t('Select a service:')}</label>
                    <select className="form-select" id="employeeSelect" onChange={handleServiceSelect}>
                        <option value="0">-</option>
                        {dashboardData.services.map((service) => (
                        <option key={service.serviceID} value={service.serviceID}>{`${service.serviceName}`}</option>
                        ))}
                    </select>
                </div>
                </div>
            </div>
            {selectedEmployeeID > 0 && selectedServiceID > 0 && (
            <div className="row appointments-list">
                <div className="d-flex justify-content-between mb-3">
                    <button type="button" className="btn btn-primary" onClick={handlePreviousDay}>{'<'}</button>
                    <h3>{daysOfWeek[selectedDate.getDay()]}, {selectedDate.toLocaleDateString()}</h3>
                    <button type="button" className="btn btn-primary" onClick={handleNextDay}>{'>'}</button>
                </div>
                <table className="table table-striped table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>{t('Time')}</th>
                        <th>{t('Reservation Availability')}</th>
                        <th>{t('Actions')}</th>
                        <th>{t('Reservation status')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {timeSlots.map((timeSlot) => (
                        <tr key={timeSlot.appointmentStartTime.toISOString()}>
                            <td>
                                {timeSlot.appointmentStartTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false})}
                            </td>
                            <td>
                                {timeSlot.availability === 'reserved' && <span className="badge bg-warning">{t('Reserved')}</span>}
                                {timeSlot.availability === 'unavailable' && 
                                <span className="badge bg-danger">
                                     <i 
                                        className="bi bi-info-circle me-2" 
                                        data-bs-toggle="tooltip" 
                                        data-bs-placement="top" 
                                        title=
                                        {t("Sorry, this time slot is not available as it overlaps with another appointment for the same employee but a different service. " +
                                        "Please choose another time slot or select a different service. Here is the appointment that overlaps with this time slot: " +
                                        "Customer Name: " + timeSlot.overlapAppointment.customerFirstName + " " + timeSlot.overlapAppointment.customerLastName + ", " +
                                        "Appointment Start Time: " + new Date(timeSlot.overlapAppointment.appointmentStartTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}) + ", " +
                                        "Appointment End Time: " + new Date(timeSlot.overlapAppointment.appointmentEndTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}) + ". " +
                                        "Service Name: " + timeSlot.overlapAppountmentService.serviceName
                                        )}
                                        >
                                    </i>
                                     {t('Unavailable')}
                                </span>
                                }
                                {timeSlot.availability === 'available' && <span className="badge bg-success">{t('Available')}</span>}
                            </td>
                            <td>
                            <button 
                                type="button"
                                disabled={timeSlot.availability === 'unavailable'}
                                onClick={timeSlot.availability === 'reserved' ? () => openEditAppointmentModal(timeSlot.appointmentID) : () => openAddNewAppointmentModal(timeSlot.appointmentStartTime)}
                                className={`btn ${timeSlot.availability === 'reserved' ? 'btn-warning' : 'btn-success'}`}>
                                    {timeSlot.availability === 'reserved' ? t('Edit') : t('Add')}
                            </button>
                            </td>
                            <td>
                                {timeSlot.status === 'pending' && <span className="badge bg-warning">{t('Need confirmation')}</span>}
                                {timeSlot.status === 'confirmed' && <span className="badge bg-success">{t('Confirmed')}</span>}
                                {timeSlot.status === 'cancelled' && <span className="badge bg-danger">{t('Cancelled')}</span>}
                                {timeSlot.status === 'completed' && <span className="badge bg-secondary">{t('Completed')}</span>}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            )}
            <AddNewAppointmentModal
                show={showAddNewAppointmentModal}
                onHide={() => setShowAddNewAppointmentModal(false)}
                selectedDate={selectedDate}
                selectedEmployeeID={selectedEmployeeID}
                selectedServiceID={selectedServiceID}
                dashboardData={dashboardData}
                appointmentStartTime={appointmentStartTime}
                setSuccessMessage={setSuccessMessage}
                addNewAppointmentToDashboardData={addNewAppointmentToDashboardData}
            />
            <EditAppointmentModal
                show={showEditAppointmentModal}
                onHide={() => setShowEditAppointmentModal(false)}
                editAppointmentData={editAppointmentData}
                setSuccessMessage={setSuccessMessage}
                updateAppointmentData={updateAppointmentData}
            />
        </div>
    );
}

export default Appointments;