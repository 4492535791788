import AppointmentsModel from '../models/AppointmentsModel';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

const fetchAppointmentsByStoreID = async (storeID) => {
    try{
        const appointmentsBody = {
            storeID: storeID, // pass storeID as a parameter
        };
        const appointmentsResponse = await fetch(`${API_ENDPOINT_URL}/appointments/get-appointments-by-store-id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': process.env.REACT_APP_API_TOKEN
            },
            body: JSON.stringify(appointmentsBody)
        });
        if (appointmentsResponse.ok) {
            const appointmentsData = await appointmentsResponse.json();
            return new AppointmentsModel(appointmentsData);
        }else {
            console.log("Getting appointments Error: " + appointmentsResponse.status + " " + appointmentsResponse.statusText);
        }
    }catch(e){
        console.error('Error fetching appointments data:', e);
    }
    
    return null;
};

const addAppointment = async (appointmentData) => {
    try {
        const response = await fetch(`${API_ENDPOINT_URL}/appointments/add-appointment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': process.env.REACT_APP_API_TOKEN
        },
        body: JSON.stringify(appointmentData),
        });

        if (response.ok) {
            const newAppointmentData = await response.json();
            console.log(newAppointmentData);
            return new AppointmentsModel(newAppointmentData);
        } else {
            console.error("Adding appointment error:", response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error adding appointment:', error);
    }
};

export { fetchAppointmentsByStoreID, addAppointment };