import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input'

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function EditAppointmentModal(props) {
    // params
    const { editAppointmentData, show, onHide, setSuccessMessage, updateAppointmentData } = props;
    const { t } = useTranslation();

    // states
    const [appointmentData, setAppointmentData] = useState({
        customerFirstName: "",
        customerLastName: "",
        customerEmail: "",
        appointmentStatus: "pending",
        customerPhoneNumber: ""
    });

    const [errors, setErrors] = useState([]);
    
    // update appointmentData state when editAppointmentData prop changes
    useEffect(() => {
        setAppointmentData(editAppointmentData);
    }, [editAppointmentData]);

    const submitEditAppointment = (e) => {
        e.preventDefault();
        callAPIToUpdateAppointment();
    }
    const callAPIToUpdateAppointment = async () => {
        if(!validateForm(appointmentData)){
            return;
        }
        try {
            const response = await fetch(`${API_ENDPOINT_URL}/appointments/update-appointment`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'API-TOKEN': process.env.REACT_APP_API_TOKEN
                },
                body: JSON.stringify(appointmentData),
            });
            const data = await response.json();
            if(data.status === 'success'){
                console.log(data.message);
                setSuccessMessage(t(data.message));
                onHide();
                // update data in dashboardData state
                updateAppointmentData(data.data);
            } else if(data.error){
                setErrors([t('Something went wrong. Please try again later.')]);
            } 
        }catch(err){
            setErrors([t('Something went wrong. Please try again later.')]);
        }
    }
    const handleOnChangeEditAppointment = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const newAppointmentData = { ...appointmentData, [name]: value };
        // set new appointment state;
        if(validateForm(newAppointmentData)){
            setErrors([]);
        }
        setAppointmentData(newAppointmentData);
    }

    const handlePhoneChange = (phone) => {
        if (phone && phone.toString()) {
            const newAppointmentData = { ...appointmentData, customerPhoneNumber: phone.toString() };
            if(validateForm(newAppointmentData)){
                setErrors([]);
            }
            setAppointmentData(newAppointmentData);
        }
    }

    const validateForm = (newAppointment) => {
        const errors = [];
        let hasErrors = false;
        for(let field in newAppointment) {
            switch (field) {
                case "customerFirstName":
                    if(newAppointment[field].length < 2){
                        errors.push(t('First Name must be at least 3 characters'));
                        hasErrors = true;
                    }
                    break;
                case "customerLastName":
                    if(newAppointment[field].length < 2){
                        errors.push(t('Last Name must be at least 3 characters'));
                        hasErrors = true;
                    }
                    break;
                case "customerEmail":
                    if (!newAppointment[field].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                        errors.push("Email address is invalid");
                        hasErrors = true;
                    }
                    break;
                case "customerPhoneNumber":
                    if (!isValidPhoneNumber(newAppointment[field])) {
                        errors.push("Phone number is invalid");
                        hasErrors = true;
                    }
                    break;
                default:
                    break;
            }
        }
        setErrors(errors);
        return !hasErrors;
    }

    return (
        <div className="edit-appointment-modal-container">
            { show && (
                 <div className="modal fade show" style={{ display: 'block' }} id="addNewAppointmentModal" tabIndex="-1" role="dialog" aria-labelledby="addNewAppointmentModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form className="form" onSubmit={submitEditAppointment}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editAppointmentModalLabel">{t('Edit Appointment')}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label htmlFor="customerFirstName" className="form-label">{t('Customer First Name')}</label>
                                        <input type="text" className="form-control" id="customerFirstName" name="customerFirstName" value={appointmentData.customerFirstName} onChange={handleOnChangeEditAppointment} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="customerLastName" className="form-label">{t('Customer Last Name')}</label>
                                        <input type="text" className="form-control" id="customerLastName" name="customerLastName" value={appointmentData.customerLastName} onChange={handleOnChangeEditAppointment} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="customerPhoneNumber" className="form-label">{t('Customer Phone Number')}</label>
                                        <PhoneInput
                                            placeholder={t("Enter phone number")}
                                            value={appointmentData.customerPhoneNumber}
                                            onChange={handlePhoneChange}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="customerEmail" className="form-label">{t('Customer Email')}</label>
                                        <input type="text" className="form-control" id="customerEmail" name="customerEmail" value={appointmentData.customerEmail} onChange={handleOnChangeEditAppointment} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="appointmentStatus" className="form-label">{t('Appointment Status')}</label>
                                        <select className="form-select" aria-label="Default select example" name="appointmentStatus" value={appointmentData.appointmentStatus} onChange={handleOnChangeEditAppointment}>
                                            <option value="pending">{t('Pending')}</option>
                                            <option value="confirmed">{t('Confirmed')}</option>
                                            <option value="completed">{t('Completed')}</option>
                                            <option value="cancelled">{t('Cancelled')}</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                    {Object.keys(errors).length > 0 &&
                                        Object.entries(errors).map(([fieldName, errorMessage]) => (
                                        <div key={fieldName} className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onHide}>{t('Close')}</button>
                                    <button type="submit" className="btn btn-primary">{t('Save changes')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EditAppointmentModal;