class WorkingHoursModel {
    constructor(data) {
        this.workingHours = [];
        if (data && data.workingHours) {
            this.workingHours = data.workingHours.map((workigHoursData) => {
                return {
                    workingHoursID: workigHoursData.workingHoursID,
                    workingHoursStartTime: workigHoursData.workingHoursStartTime,
                    workingHoursEndTime: workigHoursData.workingHoursEndTime,
                    workingHoursDayOfWeek: workigHoursData.workingHoursDayOfWeek,
                    employeeID: workigHoursData.employeeID
                };
            });
        }
    }
}

export default WorkingHoursModel;