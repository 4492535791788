import EmployeesModel from '../models/EmployeesModel';

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

const fetchEmployeesByStoreID = async (storeID) => {
    try{
        const employeesBody = {
            storeID: storeID // pass storeID as a parameter
        };
        const employeesResponse = await fetch(`${API_ENDPOINT_URL}/employees/get-employees-by-store-id`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'API-TOKEN': process.env.REACT_APP_API_TOKEN
            },
            body: JSON.stringify(employeesBody)
        });
        if (employeesResponse.ok) {
            const employeesData = await employeesResponse.json();
            return new EmployeesModel(employeesData);
        }
    }catch(e){
        console.error('Error fetching employees data:', e);
    }
    
    return null;
};

export { fetchEmployeesByStoreID };