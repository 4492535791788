class ServicesModel {
    constructor(data) {
        this.services = [];
        if (data && data.services) {
            this.services = data.services.map((serviceData) => {
                return {
                    serviceID: serviceData.serviceID,
                    serviceName: serviceData.serviceName,
                    serviceDuration: serviceData.serviceDuration,
                    servicePrice: serviceData.servicePrice
                };
            });
        }
    }
}

export default ServicesModel;