import React  from 'react';
import { useTranslation } from 'react-i18next';
import Register from "../components/Register";
import HeadData from '../components/HeadData';

const RegisterPage = () => {
  const { t } = useTranslation();
  const title = t('registerpage.title');
  const description = t('registerpage.description');
    return(
    <>
      <HeadData title={title} description={description}/>
      <Register/>
    </>
    );
};
  
export default RegisterPage;