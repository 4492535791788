import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";

const API_ENDPOINT_URL = process.env.REACT_APP_API_HOST;

function Employees(props) {

  const { t } = useTranslation();

  // params
  const { dashboardData, updateDashboardData } = props;

  // Add Employee state params
  const [employee, setEmployee] = useState({
    employeeFirstName : '',
    employeeLastName : ''
  });
  const [errorsAddEmployee, setErrors] = useState({});
  const [formAddEmployeeIsValid, setFormValid] = useState(false);

  // Edit Employee state params
  const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
  const [editEmployee, setEditEmployee] = useState({
    employeeID: 0,
    employeeFirstName: "",
    employeeLastName: "",
  });
  const [errorsEditEmployee, setErrorsEditEmployee] = useState({});
  const [formEditEmployeeIsValid, setEditEmployeeFormValid] = useState(false);

  // Handle input changes for add employee form
  const handleAddEmployeeInputChange = (e) => {
    const { name, value } = e.target;
    const newEmployee = { ...employee, [name]: value };
    setEmployee(newEmployee);
    validateForm(newEmployee);
  }
  // Add new employee
  const addEmployee = async (e) => {
    e.preventDefault();

    if (dashboardData && dashboardData.employees) {
      if (dashboardData.employees.length >= dashboardData.licence.max_employees) {
        alert(t(`You have reached the maximum number of employees for your license. Please upgrade your license to add more employees.`));
        return;
      }
    } 
    if(formAddEmployeeIsValid && dashboardData.store){
        const body = {};
        body.employeeFirstName = employee.employeeFirstName
        body.employeeLastName = employee.employeeLastName
        body.storeID = dashboardData.store.storeID
        const response = await fetch(`${API_ENDPOINT_URL}/employees/add-employee`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'API-TOKEN': process.env.REACT_APP_API_TOKEN
          },
          body: JSON.stringify(body)
        });
        if (response.ok) {
            const data = await response.json();
            if(data.errors){
                setErrors(data.errors);
            } else {
                // add employee to the dashboardData
                const newDashboardData = {
                    ...dashboardData,
                    employees: [...dashboardData.employees, data.employee]
                };
                // call the updateDashboardData function to update the state in the parent component
                updateDashboardData(newDashboardData);
                // clear the form
                setEmployee({
                    employeeFirstName : '',
                    employeeLastName : ''
                });
            }
        } else {
            // something went wrong
            console.log('Add employee failed');
        }
    } else {
        validateForm(employee);
    }
  }
  function validateForm(newEmployee, type = 'add') {
    let errors = {};
    let formIsValid = true;
    for (let field in newEmployee) {
        switch (field) {
            case 'employeeFirstName':
                if (newEmployee[field].length < 2) {
                    formIsValid = false;
                    errors[field] = t('First Name must be at least 3 characters');
                }
            break;
            case 'employeeLastName':
                if (newEmployee[field].length < 2) {
                    formIsValid = false;
                    errors[field] = t('Last Name must be at least 3 characters');
                }
                break;
            default:
            break;
        }
    }
    if(type === 'add'){
      setErrors(errors);
      setFormValid(formIsValid);
    } else if(type === 'edit') {
      setErrorsEditEmployee(errors);
      setEditEmployeeFormValid(formIsValid);
    }
  }
  // Delete employee when clicked on delete button
  const deleteEmployee = async (employeeID) => {
    if(employeeID > 0){
      if (window.confirm(t('Are you sure you want to delete this employee?'))) {
        fetch(`${API_ENDPOINT_URL}/employees/delete-employee?employeeID=${employeeID}`, {
          method: 'POST',
          headers: {
            'API-TOKEN': process.env.REACT_APP_API_TOKEN
          },
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // remove the employee from the dashboardData
          const newDashboardData = {
            ...dashboardData,
            employees: dashboardData.employees.filter(employee => employee.employeeID !== employeeID)
          };
          // call the updateDashboardData function to update the state in the parent component
          updateDashboardData(newDashboardData);
          return response.json();
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
      }
    }
  }
  // Open Edit Employee Modal and fill the form with the employee data
  const openEditEmployeeForm = (employeeID) => {
    setShowEditEmployeeModal(true);
    const employee = getEmployeeDataByEmployeeID(employeeID);
    // set edit employee state
    setEditEmployee({
      employeeID: employeeID,
      employeeFirstName: employee.employeeFirstName,
      employeeLastName: employee.employeeLastName,
    });
    // set edit employee form validation state
    setErrorsEditEmployee({});
  }
  // Close Edit Employee Modal
  const handleClose = () => setShowEditEmployeeModal(false);
  // Submit Edit Employee Form
  const submitEditEmployee = (e) => {
    e.preventDefault();
    updateEmployee(e);
  }
  const updateEmployee = async (e) => {
    e.preventDefault();
    if(formEditEmployeeIsValid && dashboardData.store && editEmployee.employeeID > 0){
        const body = {};
        body.employeeFirstName = editEmployee.employeeFirstName
        body.employeeLastName = editEmployee.employeeLastName
        body.storeID = dashboardData.store.storeID
        body.employeeID = editEmployee.employeeID
        const response = await fetch(`${API_ENDPOINT_URL}/employees/edit-employee`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'API-TOKEN': process.env.REACT_APP_API_TOKEN
            },
            body: JSON.stringify(body)
        });
        if (response.ok) {
            const data = await response.json();
            if(data.errors){
              setErrorsEditEmployee(data.errors);
            } else {
              // find the employee in the dashboardData and update it
              const newDashboardData = {
                ...dashboardData,
                employees: dashboardData.employees.map(employee => {
                  if(employee.employeeID === data.employee.employeeID){
                    return data.employee;
                  } else {
                    return employee;
                  }
                })
              };
              // call the updateDashboardData function to update the state in the parent component
              updateDashboardData(newDashboardData);
              // close the modal
              setShowEditEmployeeModal(false);
            }
        } else {
            // something went wrong
            console.log('Update employee failed');
        }
    } else {
        validateForm(editEmployee, 'edit');
    }
  }
  // Get employee data from dashboardData by employeeID
  const getEmployeeDataByEmployeeID = (employeeID) => {
    const employee = dashboardData.employees.find(employee => employee.employeeID === employeeID);
    return employee;
  }
  const handleInputChangeEditEmployee = (e) => {
    const { name, value } = e.target;
    const newEmployee = { ...editEmployee, [name]: value };
    setEditEmployee(newEmployee);
    validateForm(newEmployee, 'edit');
  }
  
  return (
      <div className="container">
        {dashboardData.store ? (
          <div>
            <div className="row">
              <div className="col">
                <h2>{t('Add New Employee')}</h2>
                <form onSubmit={addEmployee}>
                  <div className="mb-3">
                    <label htmlFor="employeeFirstName" className="form-label">{t('First Name')}</label>
                    <input type="text" className="form-control" id="employeeFirstName" name="employeeFirstName" onChange={handleAddEmployeeInputChange} value={employee.employeeFirstName} />
                  </div>
    
                  <div className="mb-3">
                    <label htmlFor="employeeLastName" className="form-label">{t('Last Name')}</label>
                    <input type="text" className="form-control" id="employeeLastName" name="employeeLastName" onChange={handleAddEmployeeInputChange} value={employee.employeeLastName} />
                  </div>
    
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary green-background">{t('Add Employee')}</button>
                  </div>
    
                  <div className="mb-3">
                    {errorsAddEmployee.employeeFirstName && <div className="alert alert-danger" role="alert">{t(errorsAddEmployee.employeeFirstName)}</div>}
                    {errorsAddEmployee.employeeLastName && <div className="alert alert-danger" role="alert">{t(errorsAddEmployee.employeeLastName)}</div>}
                  </div>
                </form>
              </div>
            </div>
    
            <div className="row">
              <div className="col">
                <h2>{t('Employee List')}</h2>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{t('First Name')}</th>
                      <th>{t('Last Name')}</th>
                      <th>{t('Actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.employees?.map((employee, index) => (
                      <tr key={index}>
                        <td>{employee.employeeFirstName}</td>
                        <td>{employee.employeeLastName}</td>
                        <td>
                          <div className="d-flex">
                            <button className="btn btn-sm btn-primary me-2" onClick={() => openEditEmployeeForm(employee.employeeID)}>{t('Edit')}</button>
                            <button className="btn btn-sm btn-danger" onClick={() => deleteEmployee(employee.employeeID)}>{t('Delete')}</button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="alert alert-primary" role="alert">
              {t('You did not setup your store yet. Please setup your store to be able to add new employees.')}
          </div> 
        )}
        <div className={`modal fade ${showEditEmployeeModal ? 'show' : ''}`} style={{ display: showEditEmployeeModal ? 'block' : 'none' }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <form onSubmit={submitEditEmployee}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Edit Employee</h5>
                  <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                
                    <div className="mb-3">
                      <label htmlFor="employeeFirstName" className="form-label">{t('First Name')}</label>
                      <input type="text" className="form-control" id="employeeFirstName" name="employeeFirstName" onChange={handleInputChangeEditEmployee} value={editEmployee.employeeFirstName} />
                    </div>
      
                    <div className="mb-3">
                      <label htmlFor="employeeLastName" className="form-label">{t('Last Name')}</label>
                      <input type="text" className="form-control" id="employeeLastName" name="employeeLastName" onChange={handleInputChangeEditEmployee} value={editEmployee.employeeLastName} />
                    </div>
      
                    <div className="mb-3">
                      {errorsEditEmployee.employeeFirstName && <div className="alert alert-danger" role="alert">{t(errorsEditEmployee.employeeFirstName)}</div>}
                      {errorsEditEmployee.employeeLastName && <div className="alert alert-danger" role="alert">{t(errorsEditEmployee.employeeLastName)}</div>}
                    </div>
                </div>
                <div className="modal-footer">
                  <div className="mb-3">
                    <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-dismiss="modal">{t('Close')}</button>
                  </div>
                  <div className="mb-3">
                      <button type="submit" className="btn btn-primary green-background">{t('Submit')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Employees;