class AppointmentsModel {
    constructor(data) {
        this.appointments = [];
        if (data && data.data) {
            if (Array.isArray(data.data)) {
                this.appointments = data.data.map((appointmentsData) => {
                    return {
                        appointmentID: appointmentsData.appointmentID,
                        employeeID: appointmentsData.employeeID,
                        serviceID: appointmentsData.erviceID,
                        appointmentStartTime: appointmentsData.appointmentStartTime,
                        appointmentEndTime: appointmentsData.appointmentEndTime,
                        appointmentStatus: appointmentsData.appointmentStatus,
                        customerFirstName: appointmentsData.customerFirstName,
                        customerLastName: appointmentsData.customerLastName,
                        customerPhoneNumber: appointmentsData.customerPhoneNumber,
                        customerEmail: appointmentsData.customerEmail
                    };
                });
            } else if (data.data) {
                return {
                    appointmentID: data.data.appointmentID,
                    employeeID: data.data.employeeID,
                    serviceID: data.data.erviceID,
                    appointmentStartTime: data.data.appointmentStartTime,
                    appointmentEndTime: data.data.appointmentEndTime,
                    appointmentStatus: data.data.appointmentStatus,
                    customerFirstName: data.data.customerFirstName,
                    customerLastName: data.data.customerLastName,
                    customerPhoneNumber: data.data.customerPhoneNumber,
                    customerEmail: data.data.customerEmail
                };
            }
        }
    }
}

export default AppointmentsModel;