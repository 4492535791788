import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
const currentYear = new Date().getFullYear();
const { t } = useTranslation();
  return (
    <footer className="bg-light py-3">
      <p className="text-center">&copy; 2022-{currentYear} Beramel.com - {t('All rights reserved')}</p>
    </footer>
  );
};

export default Footer;