import React  from 'react';
import { useTranslation } from 'react-i18next';
import Login from "../components/Login";
import HeadData from '../components/HeadData';
const LoginPage = () => {
    const { t } = useTranslation();
    const title = t('loginpage.title');
    const description = t('loginpage.description');
    return (
    <>
      <HeadData title={title} description={description}/>
      <Login/>
    </>
        
    );
};
  
export default LoginPage;