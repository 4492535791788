import React from 'react';
import { Helmet } from 'react-helmet';

const HeadData = ({ title, description, keywords, lang, noindex = false}) => {
  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {noindex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default HeadData;