import React  from 'react';
import { useTranslation } from 'react-i18next';
import StoreDashboard from "../components/StoreDashboard";
import HeadData from '../components/HeadData';

const StoreDashboardPage = () => {
  const { t } = useTranslation();
    return(
    <>
      <HeadData noindex={true}/>
      <StoreDashboard/>
    </>
    );
};
  
export default StoreDashboardPage;